.MuiContainer-root {

  &:not(.MuiContainer-disableGutters) {
    padding-left: toRem(22);
    padding-right: toRem(22);

    @include media-breakpoint-up(md) {

      @media (orientation: portrait) {
        padding-left: toRem(44);
        padding-right: toRem(44);
      }
    }

    @include media-breakpoint-up(lg) {
      padding-left: toRem(64);
      padding-right: toRem(64);
    }

    @include media-breakpoint-up(xl) {
      padding-left: toRem(88);
      padding-right: toRem(88);
    }

    @include media-breakpoint-up(xxl) {
      padding-left: toRem(120);
      padding-right: toRem(120);
    }
  }

}

#mapC {

  .darkMode & {
    filter: invert(1);
  }

  [role="region"] div.gm-style-iw-a {
    transform: translateY(-17px) !important;
  }
}