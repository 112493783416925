@import '/src/styles/var';
@import '/src/styles/mixins';
@import '/src/styles/grid';

.footer {
  color: $white;
  background: linear-gradient(90deg, #001A70 0%, #3A76BB 100%);
  position: relative;
  z-index: 900;

  :global(.darkMode) & {
    filter: invert(1);
    background: $grey100;
  }

  a {
    color: $white
  }

  .footerContent {
    padding-top: toRem(80);
    padding-bottom: toRem(80);

    .footerMenu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: toRem(27);
      margin-top: toRem(27);
      font-weight: 500;

      &:first-child {
        margin-top: toRem(64);
      }

      @include media-breakpoint-up(md) {
        gap: toRem(32);
        margin-top: 0;
      }

      //  fix safari < 15
      @supports not (accent-color: red) {
        gap: 0;

        >*+* {
          margin-top: toRem(27);

          @include media-breakpoint-up(md) {
            margin-top: toRem(32);
          }
        }
      }

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: toRem(16);
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -2px;
          left: 0;
          background: $white;
          transform: scale(0);
          transition: transform 0.25s;
        }

        &:hover,
        &:active,
        &:focus,
        &.active {
          cursor: pointer;
          color: $white;

          &:after {
            transform: scale(1);
          }
        }

      }

      &.footerMenuColOne {
        text-transform: uppercase;
        margin-top: toRem(64);

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }

      &.footerMenuColThree {
        font-weight: 300;
      }

    }
  }

  .copyright {
    padding: toRem(40);
    background: rgba(0, 0, 0, 0.2);

    .copyrightMenu {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: toRem(12);

      margin-bottom: toRem(18);

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-end
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: toRem(24);
      }

      //  fix safari < 15
      @supports not (accent-color: red) {
        gap: 0;

        >*+* {
          margin-left: toRem(12);

          @include media-breakpoint-up(md) {
            margin-left: toRem(24);
          }
        }
      }

      a {
        text-decoration: underline;
        position: relative;
        font-size: toRem(10);
        color: rgba($white, 0.75);
        transition: all .25s linear;

        @include media-breakpoint-up(md) {
          font-size: toRem(12);
        }

        &:hover {
          color: $white;
        }

        &::after {
          content: "";
          height: 100%;
          width: 1px;
          background-color: $white;
          position: absolute;
          right: - toRem(6);
          top: 0;

          @include media-breakpoint-up(lg) {
            right: - toRem(12);
          }
        }

        &:last-child {
          &::after {
            @include media-breakpoint-down(md) {
              content: none;
            }

            @include media-breakpoint-up(lg) {
              content: none;
            }
          }

        }

      }
    }
  }

}