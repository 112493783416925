@import '/src/styles/var';
@import '/src/styles/mixins';

.switchDarkMode {
  display: flex !important;
  @include media-breakpoint-down(lg) {
    align-items: flex-end !important;
  }
  @include media-breakpoint-up(lg) {
    align-items: flex-start !important;
  }
  :global(.MuiTypography-root) {
    display: flex;
    margin-bottom: 8px;
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
      flex-direction: row-reverse;
    }

    &::before {
      content: "";
      mask: url('/assets/images/Arrow_toggle.svg');
      width: 20px;
      height: 20px;
      mask-size: cover;
      background-color: #181b20;
      margin-left: 1.5rem;
      margin-right: 0.5rem;
      
      @include media-breakpoint-down(lg) {
        transform: scaleX(-1);
        margin-left: 0.5rem;
        margin-right: 1.5rem;
      }
    }
  }
}