@import 'var';

@mixin media-breakpoint-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    +"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin media-breakpoint-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: (map-get($breakpoints, $breakpoint) - 1)) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    +"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Functions
@function toRem($value, $baseSize: 16) {
  $remValue: ($value / $baseSize) + rem;
  @return $remValue;
}

// @mixin convertToRem($property, $value: 16) {
//   #{$property}: toRem($value, 16);

//   @include media-breakpoint-up(sm) {
//     #{$property}: toRem($value, 18); 
//   }
//   @include media-breakpoint-up(md) {
//     #{$property}: toRem($value, 16); 
//   }
//   @include media-breakpoint-up(lg) {
//     #{$property}: toRem($value, 16); 
//   }
//   @include media-breakpoint-up(xl) {
//     #{$property}: toRem($value, 20); 
//   }
//   @include media-breakpoint-up(xxl) {
//     #{$property}: toRem($value, 30); 
//   }
// }

@mixin font-gen($font: false, $size: false, $color: false, $lh: normal, $weight: normal, $ls: normal, $fs: normal) {

  @if $font {
    font-family: $font;
  }

  @if $size {
    font-size: toRem($size);
  }

  @if $color {
    color: $color;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: toRem($lh);
  }

  @if $ls {
    letter-spacing: $ls;
  }

  @if $fs {
    font-style: $fs;
  }
}

%hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}




// animazioni

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}