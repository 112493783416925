@import '/src/styles/var';
@import '/src/styles/mixins';
@import '/src/styles/grid';

body .stickyBar {
  //  fix safari < 15
  @supports not (accent-color: red) {
    gap: 0;

    >*+* {
      margin-left: toRem(20);

      @include media-breakpoint-up(md) {
        margin-left: toRem(40);
      }
    }
  }
}

.stickyBar {
  position: fixed;
  bottom: toRem(16);
  right: toRem(16);
  z-index: 9999;
  opacity: 0;
  transition: opacity .25s;

  @include media-breakpoint-up(md) {
    transition: bottom .25s;
    opacity: 1;
    position: sticky;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: toRem(40);

    bottom: toRem(-100);
    left: 0;
    right: 0;

    width: 100%;
    padding: toRem(16) toRem(90);

    background-color: rgba(255, 255, 255, .9);
    box-shadow: 0px -6px 12px -6px rgba(0, 26, 112, 0.1), 0px -8px 24px -4px rgba(0, 26, 112, 0.1);
  }

  &.stickyOn {
    opacity: 1;

    @include media-breakpoint-up(md) {
      bottom: 0;
    }
  }
}



.paper {
  padding: toRem(12) toRem(44) toRem(12) toRem(16);
  max-width: toRem(222);
  border-radius: toRem(8);
  box-shadow: 0px 8px 18px -6px rgba(0, 26, 112, 0.1), 0px 12px 42px -4px rgba(0, 26, 112, 0.1);
}