//------------------------------
// COLORS
//------------------------------
$headerColor: rgba(0, 26, 112, 0.9);

// Neutral & Grey Scale
$black: #000000;
$grey100: #181B20;
$grey90: #3A3F4B;
$grey80: #656D7F;
$grey70: #828A9B;
$grey60: #99A0AF;
$grey50: #AEB3BF;
$grey40: #C0C4CD;
$grey30: #D0D3DA;
$grey20: #DFE1E6;
$grey10: #ECEDF0;
$grey5: #F9F9FA;
$white: #ffffff;

// Green : Primary
$green900: #0E6814;
$green800: #3B8A27;
$green700: #4F9E30; // Primary
$green600: #63B23A;
$green500: #72C242;
$green400: #87CB5F;
$green300: #9ED47C;
$green200: #BAE1A2;
$green100: #D5EDC6;
$green50: #EEF8E8;

// Blue : Secondary
$blue900: #001A70; // Secondary
$blue800: #142B85;
$blue700: #1E3591;
$blue600: #284B9C;
$blue500: #2E5EA6;
$blue400: #3A76BB;
$blue300: #6096DC;
$blue200: #84B5E7;
$blue100: #B8D6EC;
$blue50: #E0EFFA;

// Blue: Support & Info
$supporto900: #002892;
$supporto800: #003AA6;
$supporto700: #0044B2;
$supporto600: #024EBE;
$supporto500: #1057C8; // Support & info
$supporto400: #4870D0;
$supporto300: #6E8AD8;
$supporto200: #99ABE3;
$supporto100: #C2CCEE;
$supporto50: #E7EBF8;

// Feedback : Success
$success: #118000;
$success60: #4F9E30;
$success50: #72C242;
$success10: #D5EDC6;

// Feedback : Alert
$alert: #804D00;
$alert60: #FF8C00;
$alert50: #FFBE16;
$alert10: #FFE8AD;

// Feedback : Error
$error: #F56565;
$error60: #CC0000;
$error50: #FD0000;
$error10: #FFCCCC;

//LongForm
$athensGray50: #F6F7F8;
$athensGray100: #ECEDF0;
$mirage50: #F3F6FB;
$mirage100: #E4EAF5;
$mirage200: #CFDBEE;
$mirage300: #AEC3E2;
$mirage950: #1A1D2E;
$emerald500: #30B762;

// Alpha : Blue
$alphaBlue90: rgba($blue900, 0.9);
$alphaBlue80: rgba($blue900, 0.8);
$alphaBlue70: rgba($blue900, 0.7);
$alphaBlue60: rgba($blue900, 0.6);
$alphaBlue50: rgba($blue900, 0.5);
$alphaBlue40: rgba($blue900, 0.4);
$alphaBlue30: rgba($blue900, 0.3);
$alphaBlue20: rgba($blue900, 0.2);
$alphaBlue10: rgba($blue900, 0.1);

// Alpha : White
$alphaWhite90: rgba($white, 0.9);
$alphaWhite80: rgba($white, 0.8);
$alphaWhite70: rgba($white, 0.7);
$alphaWhite60: rgba($white, 0.6);
$alphaWhite50: rgba($white, 0.5);
$alphaWhite40: rgba($white, 0.4);
$alphaWhite30: rgba($white, 0.3);
$alphaWhite20: rgba($white, 0.2);
$alphaWhite10: rgba($white, 0.1);

// Gradient
$gradientBlue: linear-gradient(90deg, $blue900 0%, $blue400 100%);
$gradientGreen: linear-gradient(90deg, $green900 0%, $green600 74.66%);
$gradientGreenFull: linear-gradient(90deg, $green900 0%, $green600 33.33%, $green600 66.66%, $green600 100%);
$gradientGrey: linear-gradient(90deg, $grey100 0%, $grey80 100%);
$gradientLightGrey: linear-gradient(90deg, $grey20 0%, $white 100%);
$gradientLightGreyFull: linear-gradient(90deg, $grey20 0%, $white 33%, $white 66%,$grey20 100%);

//------------------------------
// BREAKPOINTS
//------------------------------

$breakpoints: (
  sm: 576px,
  md:  768px,
  lg:  1024px,
  xlg: 1230px,
  xl: 1400px,
  xxl: 1920px,
) !default;


//------------------------------
// TYPOGRAPHY
//------------------------------

// Font 300;400;500;700
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;

// Heading
$headingFont: "EDF2020W01-Regular", Arial, sans-serif;
$headingFontLight: "EDF2020W01-Light", Arial, sans-serif;
$headingLineHeight: 1.5;

// Base
$baseFont: 'Work Sans', sans-serif;
$baseLineHeight: 1.5;


//------------------------------
// BUTTONS
//------------------------------

$buttonWidth: 220;
$buttonHeight: 56;

$buttonFontSize: 18;

