@import '/src/styles/var';
@import '/src/styles/mixins';
@import '/src/styles/grid';

.header {
  color: $white;
  background-color: $headerColor;
  padding-top: toRem(7);
  padding-bottom: toRem(7);
  width: 100%;
  transition: all 0.5s;
  top: -100%;
  z-index: 1000;
  position: relative;

  :global(.darkMode) & {
    filter: invert(1);
    background-color: $black;
  }

  @include media-breakpoint-up(md) {

    @media (orientation: portrait) {
      padding-top: toRem(22);
      padding-bottom: toRem(22);
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: toRem(22);
    padding-bottom: toRem(22);
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 0;
  }

  

  

  .menu {
    .menuItem {
      display: flex;
    }

    .item {
      color: $alphaWhite70;
      text-decoration: none;
      white-space: nowrap;
      padding: toRem(22) 0;
      position: relative;

      a {
        color: $alphaWhite70;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: 0;
        left: 0;
        background: $white;
        transform: scale(0);
        transition: transform 0.25s;
      }

      &.itemActive,
      &:hover,
      &:active,
      &:focus,
      &.active {
        cursor: pointer;
        color: $white;

        &:after {
          transform: scale(1);
        }
      }
    }

    .dropdownMenu {
      position: absolute;
      left: 0;
      top: 100%;
      right: 0;
      width: 100%;
      max-height: 0;
      min-height: 0;
      background-color: $white;
      opacity: 0;
      color: $grey80;
      transition: all 0.25s linear .5s;
      pointer-events: none;
      overflow: hidden;

      box-shadow: 0px 6px 12px -6px rgba(0, 26, 112, 0.1), 0px 8px 24px -4px rgba(0, 26, 112, 0.1);

      :global(.darkMode) & {
        filter: invert(1);
      }

      .menuTitle {
        color: $grey60;
      }

      .dropdownMenuItem {
        position: static;

        .dropdownSubMenu {
          display: none;
          position: absolute;
          top: 0;
          left: 100%;
          width: 100%;
          min-height: 100%;
          padding-left: 40px;

          :global(.MuiList-root) {
            text-transform: initial;
          }
        }

        &:hover {
          .dropdownSubMenu {
            display: block;
          }
        }
      }

      .dropdownTitle {
        text-transform: uppercase;
        color: $grey60;
        padding: toRem(12) 0;
        position: relative;

        a {
          color: inherit;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }

        &:hover {
          a:after {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
      }

      .case {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .caseImage {
          position: relative;
          width: 100%;
          // max-width: toRem(392);
          height: toRem(220);

          aspect-ratio: 392 / 222;
        }
      }
    }

    .dropdown {
      &:hover {
        .dropdownMenu {
          padding-top: toRem(28);
          padding-bottom: toRem(64);
          max-height: 100vh;
          min-height: 50vh;
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    button {
      &:hover {
        color: $white;
      }
    }
  }

  .langSelector {
    :global(.buttonText) {
      background-image: none;
    }
  }

  &.headerSticky {
    z-index: 1000;
    padding-top: toRem(7);
    padding-bottom: toRem(7);
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0px 8px 18px -6px rgba(0, 26, 112, 0.1), 0px 12px 42px -4px rgba(0, 26, 112, 0.1);

    :global(.darkMode) & {
      background-color: $grey90;
    }

    @include media-breakpoint-up(md) {

      @media (orientation: portrait) {
        padding-top: toRem(11);
        padding-bottom: toRem(11);
      }
    }

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .logo {
      width: 144px;
    }

    .menu {
      .item {
        padding: toRem(24) 0;
      }

      .dropdown {
        .dropdownMenu {
          // top: 80px;
        }

      }

      &.mainMenuSecondary {
        @include media-breakpoint-up(lg) {
          .menuItem {
            display: none;
            &:first-child,
            &:last-child {
              display: flex;
            }
          }
        }
      }
    }
  }

  .noButton {
    background: transparent;
    appearance: none;
    border: none;
    cursor: pointer;

    * {
      fill: #fff;
    }
  }

}

.logo {
  width: toRem(144);

  @include media-breakpoint-up(md) {

    @media (orientation: portrait) {
      width: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    width: auto;
  }

  svg {
    max-width: 100%;
  }
}

.header_drawer {
  min-height: 100vh;
  padding-top: toRem(25);
  padding-bottom: toRem(56);
  position: relative;

  .drawerTop {
    .searchIcon {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: toRem(0);

      svg {
        width: toRem(32);
        height: toRem(32);
        color: $green700;
      }
    }
  }

  .buttonDrawer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    height: 56px;
  }

  .dropdownTitle {
    text-transform: uppercase;
    color: $grey60;
    padding: toRem(12) 0 0;

    a {
      color: $grey60;
    }
  }
}

.mainMenuPrimary {
  :global(.MuiButtonBase-root) {
    text-transform: uppercase;
  }
}

.mainMenuSecondary {
  :global(.MuiButtonBase-root) {
    text-transform: initial;
  }
}

//  fix safari < 15
.header {
  @supports not (accent-color: red) {

    .mainMenuPrimary,
    .mainMenuSecondary,
    .formControlLabel {
      gap: 0;

      >*+* {
        margin-left: toRem(20);

        @include media-breakpoint-up(md) {
          margin-left: toRem(40);
        }
      }
    }
  }
}

.searchField {
  height: toRem(100);
  font-size: toRem(72) !important;
  margin-top: toRem(84);

  @include media-breakpoint-down(lg) {
    font-size: toRem(40) !important;
    line-height: toRem(48);
    height: toRem(48);
  }

  svg {
    fill: #fff;
    width: toRem(40);
    height: toRem(40);

    @include media-breakpoint-up(lg) {
      width: toRem(48);
      height: toRem(48);
    }

    &[data-testid='ArrowForwardIcon'] {
      cursor: pointer;
    }
  }
}

.hugePlaceholder {
  color: #fff;
}

.searchResultRow {
  background-color: $grey5;
  min-height: toRem(124);
  width: 100%;
  padding-top: toRem(12);
  padding-bottom: toRem(24);
  margin-top: toRem(16);
  align-items: center;
  margin-left: 0px;
  transition: background-color 0.25s ease;
  position: relative;

  @include media-breakpoint-down(lg) {
    padding-bottom: toRem(12);
    padding-top: toRem(12);
    min-height: toRem(92);
  }

  &:hover {
    background-color: $grey10;
  }

  .noButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: toRem(24);
    // width: toRem(48);
    // height: toRem(48);
    padding: 0;

    >svg {
      width: toRem(40);
      height: toRem(40);

      @include media-breakpoint-down(lg) {
        width: toRem(30);
        height: toRem(30);
      }
    }

    @include media-breakpoint-down(lg) {
      right: toRem(12);
      top: inherit;
      bottom: toRem(-3);
    }
  }

  .searchLabel {
    margin-left: toRem(-8);
    padding: 0 toRem(8);
    border-radius: 0 8px 0 0;
    background: linear-gradient(90deg, #001A70 0%, #3A76BB 100%);
    text-transform: uppercase;
    font-size: toRem(12);
    line-height: toRem(24);
    height: toRem(24);
    letter-spacing: 0.1em;

    @include media-breakpoint-up(md) {
      margin-left: toRem(-16);
    }

    @include media-breakpoint-up(xl) {
      margin-left: toRem(-24);
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: toRem(12);
    }
  }
}

.filterChips {

  .wordChip {
    transition: background-color .25s ease-in, color .25s ease-in;
    margin: 0 toRem(16) toRem(16) 0;
    height: toRem(32);
    background-color: $white;
    color: $green700;

    @include media-breakpoint-up(lg) {
      &:hover {
        background-color: $green700;
        color: $white;
      }
    }

    &:global(.active) {
      background-color: $green700;
      color: $white;
    }
  }
}

.resultsContainer {
  animation: fadeIn .25s ease-in;
  overflow-y: auto;
  padding-bottom: toRem(100);
  // margin-top: toRem(64);
  @extend %hide-scrollbar;

  .title {
    margin: toRem(40) 0 toRem(16);
  }

  .chipsLabel {
    text-transform: uppercase;
    color: $grey20;
    letter-spacing: 0.1em;
    margin-bottom: toRem(24);
    margin-top: toRem(64);
  }

  .loadMore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: toRem(44) 0;

    @include media-breakpoint-up(lg) {
      margin: toRem(80) 0;
    }
  }
}

.spinner {
  margin-top: 10%;
}


.stack {

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
    margin: toRem(32) 0 toRem(26) !important;
  }

  >span {
    @include media-breakpoint-down(lg) {
      display: block;
      width: 100%;
      margin-bottom: toRem(12);
    }
  }

  :global(.MuiChip-root) {

    @include media-breakpoint-down(lg) {
      margin-left: 0;
      margin-right: toRem(12);
      margin-bottom: toRem(12);
    }
  }
}

.searchDialog {
  background: linear-gradient(0.25deg, #3A76BB 0.24%, rgba(0, 26, 112, 0.9) 99.81%);

  :global(.darkMode) & {
    filter: invert(1);
  }
}